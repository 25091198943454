.repository-view {
  position: relative;
  height: auto;
  margin-bottom: 1px;
  padding-bottom: 1px;

  .repository-actions {
    position: absolute;
    margin-top: 20px;
    right: 0;
    z-index: 30;
  }
}
